// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-for-fun-tsx": () => import("./../../../src/pages/for-fun.tsx" /* webpackChunkName: "component---src-pages-for-fun-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-previous-books-tsx": () => import("./../../../src/pages/previous-books.tsx" /* webpackChunkName: "component---src-pages-previous-books-tsx" */)
}

